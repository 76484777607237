body {
  line-height: 32px;
}

blockquote {
  font-size: 1.2rem;
  font-style: italic;
  border-left: 4px solid #333;
  margin: 0;
  padding: 10px 20px;
}

blockquote p {
  margin: 0;
}

blockquote footer {
  margin-top: 10px;
  text-align: right;
  font-style: normal;
  font-weight: bold;
}